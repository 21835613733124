import vueCookies from '../../plugins/cookies'
import { formatUtcDateTime } from '../../utils/dateTimeUtils'

var AWS = require('aws-sdk')

// Set the region
AWS.config.update({
  region: process.env.VUE_APP_AWS_REGION, // aws server location
  accessKeyId: vueCookies.get('awsS3ConfId'),
  secretAccessKey: vueCookies.get('awsS3ConfSecret'),
})
// create aws service
var s3 = new AWS.S3({ apiVersion: process.env.VUE_APP_AWS_S3_API_VERSION })
var cloudfront = new AWS.CloudFront()

export function AwsSecurityUpdate(dir, file, rename, type) {
  return AwsUpdate(dir, file, rename, type, process.env.VUE_APP_AWS_S3_SECURITY_BUCKET_NAME)
}

export function AwsUpdate(dir, file, rename, type, bucket_name) {
  // console.log(bucket, file, rename)
  var uploadParams = {
    Bucket: bucket_name ? bucket_name : process.env.VUE_APP_AWS_S3_BUCKET_NAME, // bucket name
    Key: dir + '/' + (rename ?? file.name), // folder and filename
    Body: file, // file
    ACL: 'bucket-owner-full-control',
    'Access-Control-Allow-Origin': '*', // request header
    ContentType: type ?? 'image/jpeg',
  }
  var imgFile = new Promise(function (resolve) {
    // async function
    s3.upload(uploadParams, function (err, data) {
      if (err) {
        console.log('Error', err)
      }
      if (data) {
        // console.log(data)
        resolve(data)
      }
    })
  })
  return imgFile
}

export async function AWSSecurityWithURL(file) {
  try {
    const data = await AWSFindFile(process.env.VUE_APP_AWS_S3_SECURITY_BUCKET_NAME, file)
    const blob = new Blob([data.Body], { type: data.ContentType })
    return { blob, filename: file.name }
  } catch (e) {
    return
  }
}

export function AWSFindFile(bucket, file) {
  var uploadParams = {
    Bucket: bucket,
    Key: file.name,
  }
  var imgFile = new Promise(function (resolve, reject) {
    // async function
    s3.getObject(uploadParams, function (err, data) {
      if (err) {
        //console.log('Error', err)
        reject(err)
      }
      if (data) {
        // console.log('aws img', data)
        resolve(data)
      }
    })
  })
  return imgFile
}

export function AwsCreateInvalidation(paths) {
  var params = {
    DistributionId: process.env.VUE_APP_AWS_CLOUDFRONT_DISTRIBUTION_ID,
    InvalidationBatch: {
      CallerReference: formatUtcDateTime(null, 'YYYYMMDDHHmmss', true),
      Paths: {
        Quantity: paths.length,
        Items: paths,
      },
    },
  }
  var promise = new Promise(function (resolve) {
    cloudfront.createInvalidation(params, function (err, data) {
      if (err) {
        console.warn(err)
      } // an error occurred
      if (data) {
        resolve(data)
      } // successful response
    })
  })
  return promise
}
